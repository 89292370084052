.payment_details_container {
  width: 379px;
}

.payment_details_modal_details {
  background-color: #ededed;
  border-radius: 6px;
  padding: 24px 16px 10px 16px;
}
.payment_details_modal_details p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 8px;
  color: rgb(38, 38, 38, 0.75);
}
.payment_details_modal_details p span {
  float: right;
  font-weight: bold;
}

.payment_details_modal_details hr {
  margin-top: 0;
  margin-bottom: 14px;
  border: 0.5px solid rgb(38, 38, 38, 0.1);
}

@media screen and (max-width: 48rem) {
  .payment_details_container {
    width: 350px;
  }
}

@media screen and (max-width: 24rem) {
  .payment_details_container {
    width: 260px;
  }
  .payment_details_modal_details p {
    font-size: 0.8rem;
  }
}
