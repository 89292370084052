.delete_banner {
  position: absolute;
  top: -10px;
  right: -5px;
}

.banner_wrapper {
  position: relative;
}

.tag_wrapper {
  position: relative;
}

.delete_tag {
  position: absolute;
  z-index: 5;
  top: -7px;
  right: -7px;
}

.portfolio_image_update {
  border: 1px solid black;
  border-radius: 6px;
  width: 200px;
  margin: 0 20px;
}

.portfolio_image_wrapper {
  position: relative;
}

.delete_portfolio_image {
  position: absolute;
  z-index: 5;
  top: -120px;
  right: 30px;
}
