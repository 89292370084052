.banner_image {
  min-width: 686px;
  height: 253px;
  background: var(--black);
  border-radius: 10px;
}
.portfolio_image {
  border: 1px solid black;
  border-radius: 6px;
}
