.contract_container{
    width: 70vw;
  }

  .accept_contract_container {
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    margin-top: 15px;
    padding-bottom:10px;
  }
  
  .contract_signature_title{
    display:flex;
    justify-content:space-between;
    width:300px;
    margin-bottom:10px;
  }
  
  .accept_contract_container .lg_green_button{
    margin-top:10px;
  }

  @media screen and (max-width: 62rem) {
    .contract_container{
      width: 95vw;
    }
  }
  