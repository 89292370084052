:root {
  --green: #37c452;
  --black: #262626;
  --grey: #858585;
  --red: rgb(255, 90, 90);
  --white: #ffffff;
  --bg_grey: #fafafa;
  --grey_label: #9a9a9a;
  --modal_background: #00000033;
  --tag_color: #909090;
  --profile_border: #d6d6d6;
}

.input_adornment p {
  font-size: 1rem;
}

.content_creation_container p{
  margin: 10px 0 10px;
}