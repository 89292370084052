.success_modal_background_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal_background);
  z-index: 90;
}

.success_modal_container {
  position: fixed;
  background: var(--black);
  height: auto;
  max-width: 438px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0px 0px 16px #00000029;
  z-index: 90;
}

.success_modal_image {
  width: 27.4rem;
  height: 18.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success_modal_circle {
  background: var(--green);
  border-radius: 50%;
  width: 9.75rem;
  height: 9.75rem;
}
.success_modal_circle img {
  width: 9.75rem;
  height: 9.75rem;
}

.success_modal_title {
  background: var(--white);
  color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1.87rem;
  border-radius: 0 0 10px 10px;
}

.success_modal_title h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: "montserrat", sans-serif;
  line-height: 1.8rem;
  font-weight: normal;
  margin: 0;
}

.success_modal_title p {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "montserrat", sans-serif;
  font-weight: 300;
  margin-top: 22px;
}

@media screen and (max-width: 24rem) {
  .success_modal_container {
    width: 95vw;
  }
  .success_modal_image {
    width: 95vw;
  }
}
